<template>
    <v-row style="color: rgb(26, 25, 25)!important">
      <v-col
        cols="12"
        class="text-center pb-0 mt-0"
      >
        <router-link :to="'/savings/clients/profile/account-settings/'+loan.user_id">
            <h2 class="font-weight-bold text-left"><u>Client: {{loan.username}} - {{ loan.serial_no }}</u></h2>
        </router-link>
      </v-col>

      <v-col cols="12" style="color: rgb(26, 25, 25)!important">
            <v-card class="mt-1" >
                <v-card-text v-if="loan.target_amount && loan.target_date" class="pa-2" style="font-size: 12px; ">
                    <h3 class="font-weight-bold text-left">{{loan.name}}</h3>
                    <span class="dark--text text-left subheading"><b>Amount Saved:</b> 
                        <span class="pa-0 ma-1 dark--text text-left body-2"> {{loan.saved_amount}} FCFA in {{loan.passed_days}} days</span>
                    </span>
                </v-card-text>
                <v-card-text v-else-if="loan.target_amount" class="pa-2" style="font-size: 12px; ">
                    <h3 class="font-weight-bold text-left">{{loan.name}}</h3>
                    <span class="dark--text text-left subheading"><b>Amount Saved:</b> 
                        <span class="pa-0 ma-1 dark--text text-left body-2"> {{loan.saved_amount}} FCFA </span>
                    </span>
                </v-card-text>
                <v-card-text v-else-if="loan.target_date" class="pa-2" style="font-size: 12px; ">
                    <h3 class="font-weight-bold text-left">{{loan.name}}</h3>
                    <span class="dark--text text-left subheading"><b>Amount Saved:</b> 
                        <span class="pa-0 ma-1 dark--text text-left body-2"> {{loan.saved_amount}} FCFA </span>
                    </span>
                </v-card-text>
                <v-card-text v-else class="pa-2" style="font-size: 12px; ">
                    <h3 class="font-weight-bold text-left">{{loan.name}}</h3>
                    <span class="dark--text text-left subheading"><b>Amount Saved:</b> 
                        <span class="dark--text text-left body-2"> {{loan.saved_amount}} FCFA </span>
                    </span>
                </v-card-text>

                <v-card-text class="body-2 pt-0 px-2 pb-2" style="font-size: 12px; padding: 5px">
                    <span><b>Creation Date:</b> <span class="caption"> {{loan.get_date_str}}</span></span>
                    <v-spacer></v-spacer>
                    <div v-if="loan.target_amount && loan.target_date" >
                        <span><b>Objective:</b> <span class="caption"> Reach {{loan.target_amount}} F CFA By the {{loan.target_date}} </span> </span>
                        <v-spacer></v-spacer>
                        <span><b>Remaining:</b> <span class="caption"> {{loan.remaining_days}} days left</span></span>
                    </div>
                    <div v-else-if="loan.target_amount" >
                        <span><b>Objective:</b> <span class="caption"> Reach {{loan.target_amount}} F CFA </span> </span>
                        <v-spacer></v-spacer>
                        <span><b>Remaining:</b> <span class="caption"> {{loan.remaining_days}} F CFA</span></span>
                    </div>
                    <div v-else-if="loan.target_date" >
                        <span><b>Objective:</b> <span class="caption"> On the {{loan.target_date}} </span> </span>
                        <v-spacer></v-spacer>
                        <span><b>Remaining:</b> <span class="caption"> {{loan.remaining_days}} days left</span></span>
                    </div>
                    <v-spacer></v-spacer>
                    <span><b>Percentage Complete: </b> <span class="caption"> {{loan.percentage_completed}}</span></span>
                    <v-spacer></v-spacer>
                    <span><b>Description: </b> <span class="caption"> {{loan.description}}</span></span>
                </v-card-text>
            </v-card>

            <h2 v-if="loanRequestedTransactions.length !== 0" class="mt-3 dark--text font-weight-bold text-left"><u>Requested Transactions</u></h2>
            <div v-if="loanRequestedTransactions.length !== 0" class="white elevation-6 pa-2" style="max-height: 50px; overflow: auto; border-radius: 7px"> 
                <div color="white" style="" v-for="item in loanRequestedTransactions" :key="item.id">
                    <v-card-text class="pa-1" style="font-size: 12px;">
                        <v-row v-if="!item.cancelled || item.withdrawal || (!item.withdrawal && !item.confirmed)">
                            <v-col cols="8" class="text-left">
                                <div v-if="item.withdrawal" > <!-- this will be an interrupt-->
                                    <span class="red--text font-weight-bold">Interrupt:</span><span> {{item.amount}} FCFA</span>
                                </div>
                                <div v-else>
                                    <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                                </div>
                                <div v-if="!item.confirmed && !item.cancelled" xs8 text-left>
                                    <span class="font-weight-bold ">Status:</span><span class="primary--text font-weight-bold "> Pending </span>
                                </div>
                                <div v-if="item.confirmed" xs8 text-left>
                                    <span class="font-weight-bold ">Status:</span><span class="success--text font-weight-bold "> Confirmed </span>
                                </div>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_date_str}}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                </div>
            </div>

            <h2 class="mt-3 dark--text font-weight-bold text-left"><u>Validated Transactions</u></h2>
            <div class="white elevation-6 pa-2" style="max-height: 300px; overflow: auto; border-radius: 7px"> 
                <div color="white" style="" v-for="item in loanTransactions" :key="item.id">
                    <v-card-text class="pa-1" style="font-size: 12px;">
                        <v-row>
                            <v-col cols="6" class="text-left">
                                <div v-if="item.withdrawal" >
                                    <span class="dark--text font-weight-bold">Tranfered:</span><span> {{item.amount}} FCFA</span>
                                </div>
                                <div v-else>
                                    <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_day_month_year_str}}</span>
                            </v-col>
                            <v-col cols="8" class="text-left">
                                <span class="dark--text font-weight-bold ">By:</span><span> {{item.top_up_admin_username}} - {{item.top_up_admin_phone_number}}</span>
                            </v-col>
                            <v-col cols="4" text-right>
                                <span class="dark--text font-weight-bold ">at</span><span> {{item.transaction_time}}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                <v-divider></v-divider>
                </div>
            </div>
      </v-col> 
    </v-row>
</template>

<script>
    import axios from 'axios'
    import 'animate.css'

    export default {
        data() {
            return {
                loan: [],
                loanTransactions: [],
                loanRequestedTransactions: [],
                interrupt_fees: '',
                blink: true,
            }
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getLoanDetail();
            this.getLoanTransactions();
            this.getLoanRequestedTransactions();
            document.title = "PettyCash: Manager | Loan Detail Info."
        },
        
        methods:{
            async getLoanDetail(){                
                const loan_id = this.$route.params.id  // get loan category from url and send request to server
                await axios
                    .get('/api/v1/manager/get/user/loan/detail/'+loan_id+'/')
                    .then(response => {
                        this.loan = response.data  // get the data and fill into loans
                        console.log(this.loan)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
            
            async getLoanTransactions(){
                const loan_id = this.$route.params.id  // get loan category from url and send request to server
                await axios
                    .get('/api/v1/manager/get/user/loan/'+loan_id+'/transactions/')
                    .then(response => {
                        this.loanTransactions = response.data  // get the data and fill into loans
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
            async getLoanRequestedTransactions(){
                const loan_id = this.$route.params.id  // get loan category from url and send request to server
                await axios
                    .get('/api/v1/manager/get/user/loan/'+loan_id+'/requested_transactions/')
                    .then(response => {
                        this.loanRequestedTransactions = response.data  // get the data and fill into loans
                        console.log(this.loanRequestedTransactions)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
        },

    }
</script>
